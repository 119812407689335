<div class="page">
  <div class="toolbar">
    <mat-toolbar>
      <span>Playful Productions</span>
    </mat-toolbar>
  </div>

  <div class="content">
    <h1>404 - Page not found</h1>
    <p>The  requested page does not exist</p>
    <p>
    <a href="/login"Login>Login</a>
    </p>
  </div>
  <div class="footer">
    <mat-toolbar>
      <div>Powered by the AuguSoft Framework<mat-icon>copyright</mat-icon></div>
    </mat-toolbar>
  </div>
</div>
