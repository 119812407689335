import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AsfUser, AuthService } from '@augusoft/asf-auth';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {
  user: AsfUser;
  constructor(
    private authService: AuthService,
    private router: Router
  ) {
      this.authService.user.subscribe(v => {
        this.user = v;
        if (!v) {
          this.router.navigate(['login']);
        }
      }
      );

  }

  logout(): void {
    this.authService.logout();
  }
  ngOnInit(): void {
  }

}
