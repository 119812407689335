import { Component, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { AsfUser, AuthService } from "@augusoft/asf-auth";
import { environment } from "projects/portal/src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  user: AsfUser;
  title: string = "";
  staging: boolean = environment.staging;

  constructor(private auth: AuthService) {
    this.auth.user.subscribe((u) => {
      this.user = u;
    });
    this.title = environment.domain;
  }

  logout(): void {
    this.auth.logout();
  }
}
