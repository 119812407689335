<div class="page mat-app-background">
  <mat-toolbar [className]="staging ? 'staging' : ''">
    <span>{{ title }}</span>
    <span class="toolbar-spacer"></span>
    <button *ngIf="user" mat-button [matMenuTriggerFor]="menu"><mat-icon>menu</mat-icon></button>
  </mat-toolbar>

  <div class="content mat-app-background">
    <router-outlet></router-outlet>
  </div>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
