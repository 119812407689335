import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { environment } from "../environments/environment";
import { AuthGuardService, ASFAuthModule } from "@augusoft/asf-auth";
// import { QuizEditorComponent } from './pages/quiz-editor/quiz-editor.component';

@NgModule({
  declarations: [AppComponent, PageNotFoundComponent, LoginComponent, DashboardComponent],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule, BrowserAnimationsModule, MatIconModule, MatToolbarModule, MatButtonModule, MatMenuModule, ASFAuthModule],
  providers: [{ provide: "apiUrl", useValue: environment.apiUrl }, { provide: AuthGuardService }],
  bootstrap: [AppComponent],
})
export class AppModule {}
